import React from 'react'
import { getIsClient } from '../helpers/utilities'
import { Loader } from '../components/Loader'
const Preview = React.lazy(() => import('../config/preview'))

const PreviewPage = () => {
  if (!getIsClient()) return null
  return (
    <React.Suspense fallback={<Loader />}>
      <Preview />
    </React.Suspense>
  )
}

export default PreviewPage
